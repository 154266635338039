@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);

/* Tailwind custom styles */
.delete-button {
  font-size: theme("fontSize.lg");
}

.delete-button:hover {
  color: theme("colors.red.600");
}

/*Scroll bar style */
/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888893;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}

/* End of scrollbar style */

/* Progressbar */
.progressbar-complete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0%;
  height: 1.4rem;
  border-radius: 0.5rem;
  transition: 0.5s ease-in-out;
}

/* Items buttons */
.item-buttons,
.star-button {
  color: darkgray;
}

.item-buttons:hover {
  color: gray;
}

.active-item-button,
.active-item-button:hover {
  color: #308565;
}

.star-button:hover {
  color: gray;
}

.star-button:active {
  color: gold;
}

/* Cookies */
body .cookie-consent {
  background: rgba(43, 55, 59, 0.7) !important;
  position: fixed !important;
  bottom: 1rem !important;
  left: 1rem !important;
  width: 35% !important;
  max-width: 35% !important;
  border-radius: 5px !important;
}

.cookie-consent-button {
  font-size: 13px !important;
  text-transform: uppercase !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

/* Mobile view */
@media (max-width: 1024px) {
  body .cookie-consent {
    min-width: 100% !important;
    width: 100% !important;
    left: 0 !important;
    bottom: 0 !important;
  }

  .cookie-consent-button {
    margin-left: 220% !important;
    width: 100% !important;
  }
}
